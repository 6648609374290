import styles from './HistoryItem.module.css';
import PropTypes from 'prop-types';

const HistoryItem = ({ team, move }) => {
  return (
    <li
      className={
        styles.item +
        ' ' +
        (team === 'black'
          ? styles.item__black
          : team === 'white'
          ? styles.item__white
          : '')
      }
    >
      <span className={styles.item__span}>
        {team === 'black' ? 'Черные' : team === 'white' ? 'Белые' : ''}
      </span>
      <span className={styles.item__span}>
        {move.substring(0, 2) + ' ' + '-' + ' ' + move.substring(2)}
      </span>
    </li>
  );
};

HistoryItem.propTypes = {
  team: PropTypes.string,
  move: PropTypes.string,
};

export default HistoryItem;
