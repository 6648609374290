import styles from './LeftInfoDesk.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';

import API from '@api';
import { setHistoryList, undoMove } from '@store/historySlice';
import MovesBlock from '@components/MovesBlock';
import HistoryBlock from '@components/HistoryBlock';
import Button from '@components/UI/Button';
import GreatestMoveBlock from '@components/GreatestMoveBlock';
import { setDeskFen } from '@store/currentDesk';

const LeftInfoDesk = () => {
  const dispatch = useDispatch();
  const currentDesk = useSelector((state) => state.currentDesk.desk);
  const historyList = useSelector((state) => state.history.historyList);

  useEffect(() => {
    if (currentDesk.id) {
      API.GetHistory(currentDesk.id).then((result) => {
        const constlist = result.data;
        dispatch(setHistoryList(constlist.reverse()));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDesk.id]);

  const handleUndoClick = () => {
    if (historyList.length > 0) {
      dispatch(undoMove());
      API.UndoMove({ id: currentDesk.id }).then((result) => {
        dispatch(setDeskFen(result.data.fen));
      });
    }
  };

  return (
    <div className={styles.left_block}>
      <Button option={'cancel'} text={'Отмена хода'} handle={handleUndoClick} />
      <GreatestMoveBlock />
      <MovesBlock />
      <HistoryBlock />
    </div>
  );
};

export default LeftInfoDesk;
