import styles from './SelectChessBlock.module.css';
import PropTypes from 'prop-types';

import ChessButton from '@components/ChessButton';

import wP from '@images/Chess/wP.svg';
import wN from '@images/Chess/wN.svg';
import wB from '@images/Chess/wB.svg';
import wK from '@images/Chess/wK.svg';
import wQ from '@images/Chess/wQ.svg';
import wR from '@images/Chess/wR.svg';
import bB from '@images/Chess/bB.svg';
import bK from '@images/Chess/bK.svg';
import bN from '@images/Chess/bN.svg';
import bP from '@images/Chess/bP.svg';
import bQ from '@images/Chess/bQ.svg';
import bR from '@images/Chess/bR.svg';

const SelectChessBlock = ({ color }) => {
  return (
    <div className={styles.chess_select}>
      <div className={styles.chess_select__wrapper}>
        <ChessButton
          pic={color === 'white' ? wK : bK}
          type={'k'}
          color={color === 'white' ? 'w' : 'b'}
          chessValue={color === 'white' ? 'wK' : 'bK'}
        />
        <ChessButton
          pic={color === 'white' ? wQ : bQ}
          type={'q'}
          color={color === 'white' ? 'w' : 'b'}
          chessValue={color === 'white' ? 'wQ' : 'bQ'}
        />
        <ChessButton
          pic={color === 'white' ? wB : bB}
          type={'b'}
          color={color === 'white' ? 'w' : 'b'}
          chessValue={color === 'white' ? 'wB' : 'bB'}
        />
        <ChessButton
          pic={color === 'white' ? wN : bN}
          type={'n'}
          color={color === 'white' ? 'w' : 'b'}
          chessValue={color === 'white' ? 'wN' : 'bN'}
        />
        <ChessButton
          pic={color === 'white' ? wP : bP}
          type={'p'}
          color={color === 'white' ? 'w' : 'b'}
          chessValue={color === 'white' ? 'wP' : 'bP'}
        />
        <ChessButton
          pic={color === 'white' ? wR : bR}
          type={'r'}
          color={color === 'white' ? 'w' : 'b'}
          chessValue={color === 'white' ? 'wR' : 'bR'}
        />
      </div>
    </div>
  );
};

SelectChessBlock.propTypes = {
  color: PropTypes.string,
};

export default SelectChessBlock;
