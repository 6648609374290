import {createSlice} from '@reduxjs/toolkit';

const desksSlice = createSlice({
    name: 'desks',
    initialState: {
        desks: []
    },
    reducers: {
        setDesks(state, action) {
            state.desks = action.payload
        },
        addDesk(state, action) {
            const {id, number} = action.payload
            state.desks.push({
                deskName: `Стол ${number}`,
                id: id,
                fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
            })
        },
        editDesk(state, action) {
            const {id, fen} = action.payload;
            const desk = state.desks.find((desk) => desk.id === id);
            if (desk) {
                desk.fen = fen;
            }
        },
        removeDesk(state, action) {
            const id = action.payload;
            state.desks = state.desks.filter(desk => desk.id !== id)
        }
    }
});

export const {addDesk, removeDesk, setDesks, editDesk} = desksSlice.actions;

export default desksSlice.reducer;