import styles from './Button.module.css';
import PropTypes from 'prop-types';

const Button = ({ option, text, handle, disabled }) => {
  return (
    <button
      className={
        styles.main_button +
        ' ' +
        (option === 'cancel'
          ? styles.main_button__cancel
          : option === 'control'
          ? styles.main_button__control
          : option === 'active'
          ? styles.main_button__active
          : '')
      }
      onClick={handle}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

Button.propTypes = {
  option: PropTypes.string,
  handle: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
