import {createSlice} from '@reduxjs/toolkit';

const currentDeskSlice = createSlice({
    name: 'currentDesk',
    initialState: {
        desk: {id: 0, fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'}
    },
    reducers: {
        setDesk(state, action) {
            state.desk = action.payload
        },
        setDeskFen(state, action) {
            state.desk.fen = action.payload
        }
    }
});

export const {setDesk, setDeskFen} = currentDeskSlice.actions;

export default currentDeskSlice.reducer;