import {createSlice} from '@reduxjs/toolkit';

const selectedCustomSquare = createSlice({
    name: 'selectedCustomSquare',
    initialState: {
        square: ''
    },
    reducers: {
        setCustomSquare(state, action) {
            state.square = action.payload
        }
    }
});

export const {setCustomSquare} = selectedCustomSquare.actions;

export default selectedCustomSquare.reducer;