import Button from '@UI/Button';
import styles from './CustomDeskControl.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from '@mui/material';

import { Chess } from 'chess.js';
import { changeColor, createDesk, setPosition } from '@store/customDeskSlice';
import { addDesk } from '@store/desksSlice';
import { setDesk } from '@store/currentDesk';

import API from '../../api';
import { setPiece } from '@store/selectedPiece';
import { setCustomSquare } from '@store/selectedCustomSquare';
import { useState } from 'react';

const CustomDeskControl = () => {
  const dispatch = useDispatch();
  const customDesk = useSelector((state) => state.customDesk.desk);
  const [openModal, setOpenModal] = useState(false);

  const handleChangeColor = (color) => {
    dispatch(changeColor(color));
  };

  const getFen = (positionObject) => {
    const chess = new Chess();
    console.log(positionObject);
    chess.clear();
    Object.keys(positionObject).forEach((key) => {
      const [color, type] = positionObject[key].split('');

      chess.put({ type: type, color: color.toLowerCase() }, key);
    });
    const fen = chess.fen();
    return fen;
  };

  function hasBothKings(fen) {
    const parts = fen.split(' ');

    const position = parts[0];

    const whiteKingIndex = position.indexOf('K');
    const blackKingIndex = position.indexOf('k');

    return whiteKingIndex !== -1 && blackKingIndex !== -1;
  }

  const handleSubmit = () => {
    const fen = getFen(customDesk.position).split(' ');

    const position = fen[0];
    let castlingAvailability = fen[2];
    let enPassantSquare = fen[3];
    let halfMoveClock = fen[4];
    let fullMoveNumber = fen[5];

    const newFen = `${position} ${customDesk.color} ${castlingAvailability} ${enPassantSquare} ${halfMoveClock} ${fullMoveNumber}`;

    if (hasBothKings(newFen)) {
      API.PostNewDesk({
        deskName: `Стол ${customDesk.boardNumber}`,
        fen: newFen,
      }).then((result) => {
        dispatch(
          addDesk({ id: result.data.id, number: customDesk.boardNumber }),
        );
        dispatch(
          setDesk({
            id: result.data.id,
            fen: newFen,
            deskName: `Стол ${customDesk.boardNumber}`,
          }),
        );
        dispatch(setPosition({}));
        dispatch(setPiece(undefined));
        dispatch(createDesk(0));
        dispatch(setCustomSquare(''));
      });
    } else {
      alert('Добавьте королей на доску');
    }
  };

  const handleCancel = () => {
    dispatch(setPosition({}));
    dispatch(setPiece(undefined));
    dispatch(createDesk(0));
    dispatch(setCustomSquare(''));
  };

  const handleOpen = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className={styles.control}>
        <div className={styles.control__wrapper}>
          <span className={styles.control__desk_number}>
            Стол {customDesk.boardNumber}
          </span>
          <span className={styles.control__title}>Свободная расстановка</span>
          <div className={styles.control__button_block}>
            <span className={styles.control__span}>Первый ход</span>
            <div className={styles.control__buttons}>
              <Button
                text={'Белые'}
                handle={() => {
                  handleChangeColor('w');
                }}
                option={customDesk.color === 'w' ? 'active' : 'control'}
              />
              <Button
                text={'Черные'}
                handle={() => {
                  handleChangeColor('b');
                }}
                option={customDesk.color === 'b' ? 'active' : 'control'}
              />
            </div>
            <div className={styles.control__buttons}>
              <Button
                text={'Начать'}
                handle={handleSubmit}
                option={'control'}
              />
            </div>
            <Button text={'Выйти'} option={'cancel'} handle={handleOpen} />
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
          },
        }}
        onClose={handleClose}
      >
        <div className={styles.modal__content}>
          <h2 className={styles.modal__title}>
            Вы уверены, что хотите выйти ?
          </h2>
          <div className={styles.modal__buttons}>
            <Button text={'Выйти'} handle={handleCancel} option={'cancel'} />
            <Button text={'Отмена'} handle={handleClose} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CustomDeskControl;
