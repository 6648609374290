import {createSlice} from '@reduxjs/toolkit';

const countQuery = createSlice({
    name: 'countQuery',
    initialState: {
        count: 0
    },
    reducers: {
        increment(state) {
            state.count += 1;
        }
    }
});

export const {increment} = countQuery.actions;

export default countQuery.reducer;