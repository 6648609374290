import Button from '@UI/Button';
import styles from './CreateBoardModal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { addDesk } from '@store/desksSlice';
import { setDesk } from '@store/currentDesk';
import { createDesk } from '@store/customDeskSlice';

import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import API from '@api';

const CreateBoardModal = forwardRef(({ closeModal }, ref) => {
  const desksList = useSelector((state) => state.desks.desks);
  const START_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
  const [boardNumber, setBoardNumber] = useState('');
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (boardNumber) {
      API.PostNewDesk({
        deskName: `Стол ${boardNumber}`,
        fen: START_FEN,
      }).then((result) => {
        dispatch(addDesk({ id: result.data.id, number: boardNumber }));
        dispatch(
          setDesk({
            id: result.data.id,
            fen: START_FEN,
            deskName: `Стол ${boardNumber}`,
          }),
        );
        closeModal();
      });
    } else {
      alert('Заполните поле "Номер стола"');
    }
  };

  const handleCustomClick = () => {
    if (boardNumber) {
      dispatch(createDesk(boardNumber));
      closeModal();
    } else {
      alert('Заполните поле "Номер стола"');
    }
  };

  return (
    <div className={styles.modal__wrapper} ref={ref} tabIndex={-1}>
      <h2 className={styles.modal__title}>Создать новый стол</h2>
      <div className={styles.modal__numbering}>
        <span>Номер стола</span>
        <input
          type="text"
          placeholder="12"
          onChange={(e) => setBoardNumber(e.target.value)}
        />
      </div>
      <div className={styles.modal__choosing}>
        <span>Расстановка фигур</span>
        <Button text={'Классическая'} handle={handleSubmit} />
        <Button text={'Свободная'} handle={handleCustomClick} />
      </div>
      <div className={styles.modal__cancelBlock}>
        <Button
          text={'Отмена'}
          option={'cancel'}
          handle={closeModal}
          disabled={!desksList.length}
        />
      </div>
    </div>
  );
});

CreateBoardModal.displayName = 'CreateBoardModal';

CreateBoardModal.propTypes = {
  closeModal: PropTypes.func,
};

export default CreateBoardModal;
