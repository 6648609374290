import styles from './LoginPage.module.css';
import { useState } from 'react';
import API from '@api';

const LoginPage = () => {
  const [login, setLogin] = useState('');
  const [pass, setPass] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();
    (await API.GetToken(login, pass)) ? (window.location = '/') : error();
  }

  const error = () => {
    console.log('error');
  };

  return (
    <div className={styles.login__container}>
      <div className={styles.login__window}>
        <h2 className={styles.login__title}>Авторизация</h2>
        <form action="#">
          <div>
            <input
              id={'username'}
              type="text"
              className={styles.login__input}
              onChange={(e) => {
                setLogin(e.target.value);
              }}
            />
            <label htmlFor={'username'} className={styles.login__label}>
              Логин
            </label>
          </div>
          <div>
            <input
              id={'password'}
              type="password"
              className={styles.login__input}
              onChange={(e) => {
                setPass(e.target.value);
              }}
            />
            <label htmlFor={'password'} className={styles.login__label}>
              Пароль
            </label>
          </div>
          <div className={styles.login__buttons}>
            <button className={styles.login__submit} onClick={handleSubmit}>
              Вход
            </button>
            <div className={styles.login__register_block}>
              <span>Нет аккаунта?</span>
              <a href="/" className={styles.login__link}>
                Регистрация
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
