import {createSlice} from '@reduxjs/toolkit';

const bestMoveSlice = createSlice({
    name: 'bestMove',
    initialState: {
        bestMove: ''
    },
    reducers: {
        setMove(state, action) {
            state.bestMove = action.payload
        }
    }
});

export const {setMove} = bestMoveSlice.actions;

export default bestMoveSlice.reducer;