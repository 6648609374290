import styles from './ChessButton.module.css';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { setPiece } from '@store/selectedPiece';

const ChessButton = ({ pic, chessValue }) => {
  const selectedPiece = useSelector((state) => state.selectedPiece.piece);

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setPiece(chessValue));
  };

  return (
    <button
      className={styles.chess_button}
      onClick={handleClick}
      style={
        selectedPiece.chessValue === chessValue
          ? {
              backgroundImage: `url("${pic}")`,
              backgroundColor: '#34364c',
            }
          : { backgroundImage: `url("${pic}")` }
      }
    />
  );
};

ChessButton.propTypes = {
  pic: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
  chessValue: PropTypes.string,
};

export default ChessButton;
