import { useEffect, useState } from 'react';
import styles from './GreatestMoveBlock.module.css';
import { useSelector, useDispatch } from 'react-redux';
import API from '@api';
import { setMove } from '@store/bestMoveSlice';
import { increment } from '@store/countQuery';
import { Modal } from '@mui/material';
import ErrorModal from '@components/ErrorModal';

const GreatestMoveBlock = () => {
  const dispatch = useDispatch();

  const currentDesk = useSelector((state) => state.currentDesk.desk);
  const countQuery = useSelector((state) => state.countQuery.count);
  const historyList = useSelector((state) => state.history.historyList);

  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [bestMoveResponse, setBestMoveResponse] = useState();
  const [discription, setDiscription] = useState('');
  const [openError, setOpenError] = useState(false);
  const [reset, setReset] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    dispatch(increment());
    if (currentDesk.fen) {
      API.GetBestMove(
        { fen: currentDesk.fen, deskId: currentDesk.id },
        countQuery,
      )
        .then((data) => {
          setResponse(data);
        })
        .catch(() => {
          setOpenError(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDesk, reset]);

  useEffect(() => {
    if (response) {
      if (response.countQuery === countQuery - 1) {
        setBestMoveResponse(response.data.bestmove);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (currentDesk.fen?.split(' ')[1] === 'w') {
      setDiscription('Гроссмейстер');
    }
    if (
      currentDesk.fen?.split(' ')[1] === 'b' &&
      historyList.filter((item) => item.fen?.split(' ')[1] === 'w').length %
        2 !==
        0
    ) {
      setDiscription('Ход с ИИ');
    }
    if (
      currentDesk.fen?.split(' ')[1] === 'b' &&
      historyList.filter((item) => item.fen?.split(' ')[1] === 'w').length % 2 === 0
    ) {
      setDiscription('Ход без ИИ');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyList, currentDesk.fen]);

  const setBestMove = () => {
    if (bestMoveResponse) {
      dispatch(setMove(bestMoveResponse));
    }
  };

  const handleClose = () => {
    setOpenError(false);
    window.location.reload();
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  return (
    <>
      <div
        className={
          styles.move_block + ' ' + (discription !== 'Ход с ИИ' && styles.move_block_black)
        }
      >
        {isLoading ? (
          <div className={styles.loader}>
            <span>.</span>

            <span>.</span>
            <span>.</span>
          </div>
        ) : (
          <button className={styles.move_block__button} onClick={setBestMove}>
            <span className={styles.move_block__span}>Лучший ход</span>
            <div className={styles.move_block__arrow_block}>
              <span className={styles.move_block__span}>
                {bestMoveResponse?.substring(0, 2)}
              </span>
              <div className={styles.move_block__arrow}></div>
              <span className={styles.move_block__span}>
                {bestMoveResponse?.substring(2)}
              </span>
            </div>
          </button>
        )}
        <div
          className={
            styles.move_block__discription + ' ' + (discription !== 'Ход с ИИ' && styles.move_block__discription_black)
          }
        >
          <span
            className={
              styles.move_block__disc_span
            }
          >
            {discription}
          </span>
        </div>

        <button
          className={styles.move_block__reset_button}
          onClick={handleReset}
        ></button>
      </div>
      <Modal
        open={openError}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
          },
        }}
        onClose={handleClose}
      >
        <ErrorModal handle={handleClose} />
      </Modal>
    </>
  );
};

export default GreatestMoveBlock;
