import styles from './RightTableSelect.module.css';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import { Modal } from '@mui/material';

import { setDesk } from '@store/currentDesk';

import TableButton from '@components/UI/TableButton';
import CreateBoardModal from '@components/CreateBoardModal';

const RightTableSelect = () => {
  const dispatch = useDispatch();

  const desksList = useSelector((state) => state.desks.desks);
  const currentDesk = useSelector((state) => state.currentDesk.desk);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (currentDesk.id === 0 || Object.keys(currentDesk).length === 0) {
      setOpenModal(true);
    }
  }, [currentDesk]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChooseDesk = (deskId) => {
    const selectedDesk = desksList.find((desk) => desk.id === deskId);
    dispatch(setDesk(selectedDesk));
  };

  return (
    <div className={styles.tables}>
      <div className={styles.tables__wrapper}>
        <ul className={styles.tables__list}>
          {desksList.map((desk) => (
            <TableButton
              tableIndex={desk.deskName.split(' ')[1]}
              deskId={desk.id}
              key={desk.id + 1}
              chooseDesk={handleChooseDesk}
              active={currentDesk.id == desk.id}
            />
          ))}
        </ul>
        <button className={styles.tables__add} onClick={handleOpenModal}>
          +
        </button>
      </div>

      <Modal
        open={openModal}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
          },
        }}
        onClose={
          currentDesk.id === 0 || Object.keys(currentDesk).length === 0
            ? () => {}
            : handleCloseModal
        }
      >
        <CreateBoardModal closeModal={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default RightTableSelect;
