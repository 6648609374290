import {createSlice} from '@reduxjs/toolkit';

const initialPosition = {
    a8: 'bR', b8: 'bN', c8: 'bB', d8: 'bQ', e8: 'bK', f8: 'bB', g8: 'bN', h8: 'bR',
    a7: 'bP', b7: 'bP', c7: 'bP', d7: 'bP', e7: 'bP', f7: 'bP', g7: 'bP', h7: 'bP',

    a2: 'wP', b2: 'wP', c2: 'wP', d2: 'wP', e2: 'wP', f2: 'wP', g2: 'wP', h2: 'wP',
    a1: 'wR', b1: 'wN', c1: 'wB', d1: 'wQ', e1: 'wK', f1: 'wB', g1: 'wN', h1: 'wR'
};

const customDeskSlice = createSlice({
    name: 'customDesk',
    initialState: {
        desk: {id: 0, fen: '', color: 'w', boardNumber: 0, position: {}}
    },
    reducers: {
        createDesk(state, action) {
            state.desk.boardNumber = action.payload
        },
        setPiecesPosition(state, action) {
            const {square, piace} = action.payload
            state.desk.position = {...state.desk.position, [square]: piace}
        },
        setPosition(state, action) {
            state.desk.position = action.payload
        },
        setFen(state, action) {
            state.desk.fen = action.payload
        },
        deletePiece(state, action) {
            delete state.desk.position[action.payload]
        },
        changeColor(state, action) {
            state.desk.color = action.payload;
        },
        setInitialPosition(state) {
            state.desk.position = initialPosition
        }
    }
});

export const {createDesk, setPosition, setPiecesPosition, deletePiece, setFen, changeColor, setInitialPosition} = customDeskSlice.actions;

export default customDeskSlice.reducer;