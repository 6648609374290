import styles from './ChoosePiece.module.css';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import wN from '@images/Chess/wN.svg';
import wB from '@images/Chess/wB.svg';
import wQ from '@images/Chess/wQ.svg';
import wR from '@images/Chess/wR.svg';
import bB from '@images/Chess/bB.svg';
import bN from '@images/Chess/bN.svg';
import bQ from '@images/Chess/bQ.svg';
import bR from '@images/Chess/bR.svg';

const ChoosePiece = forwardRef(({ color, handle }, ref) => {
  return (
    <div
      className={styles.modal__content}
      ref={ref}
      tabIndex="-1"
      style={color === 'white' ? { top: '25%' } : { top: '75%' }}
    >
      <div className={styles.modal__list}>
        <button
          className={styles.modal__button}
          onClick={() => handle('q')}
          style={
            color === 'white'
              ? { backgroundImage: `url('${wQ}')` }
              : { backgroundImage: `url('${bQ}')` }
          }
        ></button>
        <button
          className={styles.modal__button}
          onClick={() => handle('b')}
          style={
            color === 'white'
              ? { backgroundImage: `url('${wB}')` }
              : { backgroundImage: `url('${bB}')` }
          }
        ></button>
        <button
          className={styles.modal__button}
          onClick={() => handle('n')}
          style={
            color === 'white'
              ? { backgroundImage: `url('${wN}')` }
              : { backgroundImage: `url('${bN}')` }
          }
        ></button>
        <button
          className={styles.modal__button}
          onClick={() => handle('r')}
          style={
            color === 'white'
              ? { backgroundImage: `url('${wR}')` }
              : { backgroundImage: `url('${bR}')` }
          }
        ></button>
      </div>
    </div>
  );
});

ChoosePiece.displayName = 'ChoosePiece';

ChoosePiece.propTypes = {
  color: PropTypes.string,
  handle: PropTypes.func,
};

export default ChoosePiece;
