import styles from './MovesBlock.module.css';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {useEffect, useState} from "react";

const MovesBlock = () => {
  const historyList = useSelector((state) => state.history.historyList);
  const currentDesk = useSelector((state) => state.currentDesk.desk);

  const [moveCount, setMoveCount] = useState(1)

  useEffect(() => {
    if (currentDesk.fen?.split(' ')[1] === 'b') {
      setMoveCount((historyList.filter((item) => item.fen?.split(' ')[1] === 'w').length) + 1)
    }

    if (currentDesk.fen?.split(' ')[1] === 'w') {
      setMoveCount((historyList.filter((item) => item.fen?.split(' ')[1] === 'b').length) + 1)
    }

  }, [historyList, currentDesk.fen])

  return (
    <div
      className={
        styles.moves +
        ' ' +
        (currentDesk.fen?.split(' ')[1] === 'b' ? styles.moves_black : '')
      }
    >
      <div className={styles.moves__wrapper}>
        <span className={styles.moves__span}>{moveCount} ход</span>
        <span
          className={styles.moves__span + ' ' + styles.moves__span_overlined}
        >
          {currentDesk.fen?.split(' ')[1] === 'w' ? 'Ход белых' : 'Ход черных'}
        </span>
      </div>
    </div>
  );
};

MovesBlock.propTypes = {
  list: PropTypes.array,
};

export default MovesBlock;
