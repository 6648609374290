import styles from './App.module.css';
import LeftInfoDesk from '@containers/LeftInfoDesk';
import RightTableSelect from '@containers/RightTableSelect';
import ChessContainer from '@containers/ChessContainer';
import { useEffect, useState } from 'react';
import API from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { setDesks } from '@store/desksSlice';
import { setDesk } from '@store/currentDesk';
import CustomLeftDesk from '../CustomLeftDesk';
import CustomRightDesk from '../CustomRightDesk';
import LoginPage from '../LoginPage';
import Loading from '@components/Loading';
import { Modal } from '@mui/material';
import ErrorModal from '@components/ErrorModal';

const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [openError, setOpenError] = useState(false);
  const [auth, setAuth] = useState(false);

  const customDesk = useSelector((state) => state.customDesk.desk);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setAuth(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      API.GetAllBoards()
        .then((data) => {
          dispatch(setDesks(data.data));
          dispatch(setDesk(data.data.length > 0 ? data.data[0] : {}));
          setIsLoading(false);
        })
        .catch(() => {
          setOpenError(true);
        });
    }
  }, [dispatch]);

  const handleClose = () => {
    setOpenError(false);
    window.location.reload();
  };

  return (
    <>
      {auth ? (
        <div className={styles.app}>
          {isLoading ? (
            <Loading />
          ) : (
            <div className={styles.app__wrapper}>
              {customDesk.boardNumber ? <CustomLeftDesk /> : <LeftInfoDesk />}
              <ChessContainer />
              {customDesk.boardNumber ? (
                <CustomRightDesk />
              ) : (
                <RightTableSelect />
              )}
            </div>
          )}
        </div>
      ) : (
        <LoginPage />
      )}
      <Modal
        open={openError}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(6px)',
            WebkitBackdropFilter: 'blur(6px)',
          },
        }}
        onClose={handleClose}
      >
        <ErrorModal handle={handleClose} />
      </Modal>
    </>
  );
};

export default App;
