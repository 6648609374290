import {createSlice} from '@reduxjs/toolkit';

const selectedPiece = createSlice({
    name: 'selectedPiece',
    initialState: {
        piece: {chessValue: undefined}
    },
    reducers: {
        setPiece(state, action) {
            state.piece.chessValue = action.payload
        }
    }
});

export const {setPiece} = selectedPiece.actions;

export default selectedPiece.reducer;