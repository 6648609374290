import SelectChessBlock from '@components/SelectChessBlock';
import Button from '@components/UI/Button';
import styles from './CustomRightDesk.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPosition,
  deletePiece,
  setInitialPosition,
} from '@store/customDeskSlice';
import { setPiece } from '@store/selectedPiece';
import { setCustomSquare } from '@store/selectedCustomSquare';

const CustomRightDesk = () => {
  const dispatch = useDispatch();
  const selectedCustomSquare = useSelector(
    (state) => state.selectedCustomSquare.square,
  );
  const customDeskPosition = useSelector(
    (state) => state.customDesk.desk.position,
  );

  const handleClear = () => {
    dispatch(setPosition({}));
    dispatch(setPiece(undefined));
  };

  const handleReset = () => {
    dispatch(setInitialPosition());
  };

  const handleDelete = () => {
    dispatch(deletePiece(selectedCustomSquare));
    dispatch(setCustomSquare(''));
  };

  return (
    <div className={styles.custom_right_desk}>
      <div className={styles.custom_right_desk__wrapper}>
        <SelectChessBlock color={'black'} />
        <div className={styles.custom_right_desk__buttons}>
          <Button text={'Очистить доску'} handle={handleClear} />
          <Button text={'Начальная расстановка'} handle={handleReset} />
          <Button
            text={'Удалить фигуру'}
            handle={handleDelete}
            disabled={
              !Object.keys(customDeskPosition)?.includes(selectedCustomSquare)
            }
          />
        </div>
        <SelectChessBlock color={'white'} />
      </div>
    </div>
  );
};

export default CustomRightDesk;
