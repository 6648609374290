import {createSlice} from '@reduxjs/toolkit';

const historySlice = createSlice({
    name: 'history',
    initialState: {
        historyList: []
    },
    reducers: {
        setHistoryList(state, action) {
            state.historyList = action.payload
        },
        setHistoryItem(state, action) {
            const {desk_id, move, fen} = action.payload;

            state.historyList.unshift({desk_id: desk_id, move: move, fen: fen});
        },
        undoMove(state) {
            state.historyList.shift()
        }
    }
});

export const {setHistoryList, setHistoryItem, undoMove} = historySlice.actions;

export default historySlice.reducer;