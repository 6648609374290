import CustomDeskControl from '@components/CustomDeskControl';
import styles from './CustomLeftDesk.module.css';

const CustomLeftDesk = () => {
  return (
    <div className={styles.custom_left_desk}>
      <CustomDeskControl></CustomDeskControl>
    </div>
  );
};

export default CustomLeftDesk;
