import styles from './ChessContainer.module.css';

import MyChessBoard from '@components/MyChessBoard';

const ChestContainer = () => {
  return (
    <div className={styles.board}>
      <MyChessBoard />
    </div>
  );
};

export default ChestContainer;
