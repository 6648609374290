import styles from './ErrorModal.module.css';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const ErrorModal = forwardRef(({ handle }, ref) => {
  return (
    <div className={styles.error} ref={ref} tabIndex={-1}>
      <p className={styles.error__text}>Потеряно соединение с интернетом!</p>
      <button className={styles.error__button} onClick={handle}>
        Ок
      </button>
    </div>
  );
});

ErrorModal.propTypes = {
  handle: PropTypes.func,
};

ErrorModal.displayName = 'ErrorModal';

export default ErrorModal;
