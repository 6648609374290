import axios from 'axios';

export default class API {
    static API_URL = 'https://back.mendeleevchess.ru';

    static Token = localStorage.getItem('token') ?? "";

    static async GetToken(login, password) {
        const curToken = await axios.post(this.API_URL + '/auth', {
            "user": login,
            "password": password
        })
        localStorage.setItem("token", curToken.data.token);
        this.Token = curToken.data.token;
        return !!curToken.data.token;
    }

    static checkToken(resp) {
        return resp === 'no auth'
    }

    static async Get(url, maxRetries = 3, retryDelay = 2000) {
        try {
            const query = await axios({
                method: 'get',
                url: this.API_URL + url,
                headers: {
                    token: this.Token
                },
            })
            if (query.data.error) {
                if (this.checkToken(query.data.error)) {
                    localStorage.setItem("token", "");
                    window.location.reload();
                }
                alert(query.data.error)
            } else {
                return query
            }

        } catch (e) {
            if (maxRetries > 0) {
                await new Promise(resolve => setTimeout(resolve, retryDelay));
                return this.Get(url, maxRetries - 1, retryDelay * 2);
            }
        }
    }
    static async Post(url, data, maxRetries = 3, retryDelay = 2000) {
        try {
            const query = await axios({
                method: 'post',
                url: this.API_URL + url,
                headers: {
                    token: this.Token,
                },
                data: data
            })
            if (query.data.error) {
                if (this.checkToken(query.data.error)) {
                    localStorage.setItem("token", "");
                    window.location.reload();
                }
                alert(query.data.error)
            } else {
                return query
            }

        } catch (e) {
            if (maxRetries > 0) {
                await new Promise(resolve => setTimeout(resolve, retryDelay));
                return this.Post(url, data, maxRetries - 1, retryDelay * 2);
            }
        }
    }

    static async GetAllBoards() {
        return await this.Get('/desk/get');
    }

    static async GetBestMove(data, countQuery) {
        let query = await this.Post('/bestMove', {fen: data.fen, deskId: data.deskId})
        query.countQuery = countQuery
        return query
    }

    static async GetHistory(id) {
        return await this.Get(`/desk/history/get?deskId=${id}`);
    }

    static async PostNewDesk(data) {
        return await this.Post('/desk/create', data)
    }

    static async PostNewMove(data) {
        return await this.Post('/desk/newMove', data)
    }

    static async DeleteDesk(data) {
        return await this.Post('/desk/delete', data)
    }

    static async UndoMove(data) {
        return await this.Post('/desk/undo', data)
    }
};